import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const OButton = ({
  type,
  width,
  height,
  borderRadius,
  variant,
  content,
  contentVariant,
  onClick,
  navigateTo,
  color,
  btnContentColor,
  disabled
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const btnColor = color ? color : colors.blueAccent[400];

  return (
    <Box>
      {
      (navigateTo !== undefined) ?
      <Button
        type={type}
        variant={variant}
        sx={{
          width: width,
          height: height,
          borderRadius: borderRadius,
          backgroundColor: btnColor,
          color: btnContentColor,
        }}
        onClick={() => onClick(`/${navigateTo}`)}
      >
        <Typography variant={contentVariant}>
          {String(content).toLowerCase()}
        </Typography>
      </Button>
      :
      <Button
        type={type}
        variant={variant}
        sx={{
          width: width,
          height: height,
          borderRadius: borderRadius,
          backgroundColor: btnColor,
          color: btnContentColor,
        }}
        disabled={disabled}
      >
        <Typography variant={contentVariant}>
          {String(content).toLowerCase()}
        </Typography>
      </Button>
      }
      
    </Box>
  );
};

export default OButton;