import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import OButton from "../common/OButton";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { getIn, useFormik } from "formik";
import * as yup from "yup";
import "./Hero.css";

const Hero = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const clickHandler = (page) => navigate(page);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit: (values) => {
      console.log("Register");
    },
  });

  return (
    <Box className="HeroContainer">
      <img
        className="ImageContainer"
        alt="Your Move"
        width="100%"
        src={`../../../assets/hero-img.png`}
      />
      {/* Right CONTAINER */}
      <Box className="HeroRightContainer">
        <Box className="HeadLine">
          <Typography
            // variant="h1"
            color={colors.greenAccent[500]}
            fontSize="64px"
            fontWeight="600"
          >
            Reimagined
          </Typography>
          <br />
          <br />
          <br />
          <Typography
            display="inline"
            variant="h2"
            fontSize="48px"
            fontWeight="600"
            color={colors.blueAccent[500]}
          >
            The Future of your Dairy
          </Typography>
          <br />
          <Typography
            variant="h3"
            color={colors.blueAccent[500]}
            fontWeight="600"
          >
            Data-Driven Decisions, Anytime, Anywhere.
          </Typography>
          <form onSubmit={formik.handleSubmit} className="RegisterForm">
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              type="email"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: -1.75 }}>
                      <OButton
                        type="submit"
                        height="3.3rem"
                        variant="filled"
                        content="Submit"
                        contentVariant="h6"
                        color={colors.blueAccent[500]}
                        btnContentColor={colors.white[100]}
                      />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                width: "100%",
              }}
              value={formik.values.email}
              onChange={(event) => {
                formik.setFieldValue("email", event.target.value);
              }}
              onBlur={formik.handleBlur}
              error={Boolean(
                getIn(formik.touched, "email") && getIn(formik.errors, "email")
              )}
              helperText={
                getIn(formik.touched, "email") && getIn(formik.errors, "email")
              }
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
