import React from "react";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import "./Features.css";
import { tokens } from "../../theme";

const Features = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box className="Features">
      <Box className="FeaturesHeadline">
        <Typography
          variant="h2"
          fontWeight={700}
          color={colors.blueAccent[500]}
        >
          Milko's Features
        </Typography>
      </Box>

      {/* Feature's on Cards */}

      <Stack marginTop="40px" direction="column" spacing={5}>
        <Stack
          display="flex"
          justifyContent="center"
          spacing={5}
          direction="row"
        >
          <Card
            variant="outlined"
            sx={{
              backgroundColor: colors.greenAccent[500],
              width: "20%",
              height: "100%",
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={5}>
                <img
                  alt="Manage Data"
                  width="30%"
                  height="30%"
                  src={`../../../assets/herd-data.png`}
                />
                <Typography variant="h4" sx={{ paddingTop: "25px" }}>
                  Herd Data
                </Typography>
              </Stack>
              <Stack sx={{ marginLeft: "5px" }} spacing={2}>
                <Typography variant="h6" gutterBottom>
                  We meticulously record data for each cow, including milk
                  production, vaccination schedules, feeding habits, and other
                  vital information. This granular level of detail empowers you
                  to make informed decisions for a healthier, more productive
                  herd.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              backgroundColor: colors.blueAccent[600],
              width: "20%",
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={5}>
                <img
                  alt="Manage Data"
                  width="30%"
                  height="30%"
                  src={`../../../assets/accounting.png`}
                />
                <Typography variant="h4" sx={{ paddingTop: "25px" }}>
                  Accounting
                </Typography>
              </Stack>
              <Stack sx={{ marginLeft: "5px" }} spacing={2}>
                <Typography variant="h6" gutterBottom>
                  Focus on your business, not your bookkeeping. Easily record
                  income by entering the amount you receive. Our software will
                  automatically categorize and track transactions, saving you
                  time and effort.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              backgroundColor: colors.blueAccent[600],
              width: "20%",
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={2}>
                <img
                  alt="Manage Data"
                  width="40%"
                  height="40%"
                  src={`../../../assets/customers.png`}
                />
                <Typography variant="h4" sx={{ paddingTop: "20px" }}>
                  Customer Data
                </Typography>
              </Stack>
              <Stack sx={{ paddingTop: "5px" }} spacing={2}>
                <Typography variant="h6">
                  Consolidate your customer information in one place. Milko
                  provides secure, cloud-based storage and easy access to your
                  data. Generate customized reports tailored to your specific
                  needs, right when you need them.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              backgroundColor: colors.greenAccent[500],
              width: "20%",
            }}
          >
            <CardContent>
              <Stack direction="row" spacing={5}>
                <img
                  alt="Manage Data"
                  width="30%"
                  height="30%"
                  src={`../../../assets/data-reports.png`}
                />
                <Typography variant="h4" sx={{ paddingTop: "25px" }}>
                  Data Reports
                </Typography>
              </Stack>
              <Stack sx={{ marginTop: "20px" }} spacing={2}>
                <Typography variant="h6">
                  Customize your data view by date to get a clear picture of
                  your financial performance. Track revenue received and
                  outstanding balances at a glance.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Features;
