import {
  Box,
  Chip,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { tokens } from "../../theme";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import PersonTwoToneIcon from "@mui/icons-material/PersonTwoTone";
import OButton from "../common/OButton";
import emailjs from "@emailjs/browser";
import "./Contact.css";

const GetInTouch = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object().shape({
      fullName: Yup.string("Enter your full name")
        .required("required")
        .min(3, "Minimum 3 characters"),
      email: Yup.string("Enter your email").required("required"),
      phoneNumber: Yup.string("Enter your email")
        .required("required")
        .min(10, "Ten"),
    }),
    onSubmit: (values) => {
      emailjs
        .sendForm(
          "service_dhe9ekj",
          "template_r7vkvgd",
          formRef.current,
          "aWjPjgCKsiBAsSVeu"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      console.log("Get in touch form submitted");
      setFormSubmitted(true);
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <Box className="git-container">
      {/* Left Container*/}
      <Box className="left-container">
        <Box
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h3">Contact us</Typography>
        </Box>
        {/* FORM CONTAINER */}
        <Box>
          <form ref={formRef} onSubmit={formik.handleSubmit}>
            <Stack spacing={5}>
              <TextField
                label="Full Name"
                variant="outlined"
                type="text"
                size="small"
                value={formik.values.fullName}
                onChange={(event) => {
                  formik.setFieldValue("fullName", event.target.value);
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonTwoToneIcon />
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  width: "20rem",
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  getIn(formik.touched, "fullName") &&
                    getIn(formik.errors, "fullName")
                )}
                helperText={
                  getIn(formik.touched, "fullName") &&
                  getIn(formik.errors, "fullName")
                }
              />

              <TextField
                label="Email"
                variant="outlined"
                type="text"
                size="small"
                value={formik.values.email}
                onChange={(event) => {
                  formik.setFieldValue("email", event.target.value);
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  width: "20rem",
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  getIn(formik.touched, "email") &&
                    getIn(formik.errors, "email")
                )}
                helperText={
                  getIn(formik.touched, "email") &&
                  getIn(formik.errors, "email")
                }
              />

              <TextField
                label="Phone Number"
                variant="outlined"
                type="number"
                size="small"
                value={formik.values.phoneNumber}
                onChange={(event) => {
                  formik.setFieldValue("phoneNumber", event.target.value);
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIphoneOutlinedIcon />
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  width: "20rem",
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  getIn(formik.touched, "phoneNumber") &&
                    getIn(formik.errors, "phoneNumber")
                )}
                helperText={
                  getIn(formik.touched, "phoneNumber") &&
                  getIn(formik.errors, "phoneNumber")
                }
              />
              <Stack spacing={2} direction="row">
                {/* <SendTwoToneIcon fontSize="large"/> */}
                <OButton
                  type="submit"
                  height="2.5rem"
                  borderRadius="1.5rem"
                  variant="filled"
                  content="Submit"
                  contentVariant="h6"
                  color={colors.blueAccent[500]}
                  btnContentColor={colors.white[100]}
                />
              </Stack>
            </Stack>
          </form>
        </Box>
        {formSubmitted && (
          <Typography variant="h4" color={colors.blueAccent[500]}>
            Thank you to contacting us.
          </Typography>
        )}
      </Box>
      <Divider orientation="vertical" flexItem sx={{ height: 400 }}>
        <Chip
          label="Address"
          sx={{
            backgroundColor: colors.blueAccent[500],
            color: colors.white[100],
            fontSize: "1rem",
          }}
        />
      </Divider>
      {/* Right Container */}
      <Box className="right-container">
        <Stack spacing={2} direction="column">
          <Stack>
            <Typography variant="h3" fontWeight={600}>
              India 🇮🇳
            </Typography>
            <Typography variant="h5">B-21 Galaxy Business Park</Typography>
            <Typography variant="h5">
              Block A, Industrial Area, Sector-1
            </Typography>
            <Typography variant="h5">Dehradun</Typography>
            <Typography variant="h5">Uttarakhand-247666</Typography>
            <Typography variant="h5">
              <span className="address-title">Email:</span> india@milko.com
            </Typography>
            <Typography variant="h5">
              <span className="address-title">Phone No:</span>{" "}
              +91-(712)-727-XXXX
            </Typography>
          </Stack>
          <Divider
            orientation="horizontal"
            sx={{ border: "1px solid black" }}
          />
          <Stack>
            <Typography variant="h3" fontWeight={600}>
              USA 🇺🇸
            </Typography>
            <Typography variant="h5">7778 Priceton Hightstown Rd</Typography>
            <Typography variant="h5">Suite 21</Typography>
            <Typography variant="h5">Priceton Junction</Typography>
            <Typography variant="h5">NJ-08550</Typography>
            <Typography variant="h5"></Typography>
            <Typography variant="h5">
              <span className="address-title">Email:</span> usa@milko.com
            </Typography>
            <Typography variant="h5">
              <span className="address-title">Phone No:</span> +1-(712)-727-XXXX
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default GetInTouch;
