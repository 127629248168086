import {
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import "./About.css";
import { tokens, useMode } from "../../theme";
const About = () => {
  const [theme] = useMode();
  const colors = tokens(theme.palette.mode);


  return (
    <Stack className="AboutContainer" spacing={10}>
      <Stack alignItems="center" spacing={2}>
        <Typography
          variant="h2"
          fontWeight={600}
          color={colors.greenAccent[500]}
        >
          Why Milko?
        </Typography>

        <Typography variant="h4" color={colors.blueAccent[500]}>
          Milko is designed and developed by a dairy owner's son who actively
          works on the farm. This firsthand experience allows us to truly
          understand your daily challenges and provide tailored solutions to
          help you grow your business efficiently and sustainably.
        </Typography>
      </Stack>
      {/* Paper */}
      <Stack justifyContent="center" spacing={5} direction="row" width="100%">
        <Card
          variant="outlined"
          sx={{
            backgroundColor: colors.greenAccent[500],
            width: "20%",
          }}
        >
          <CardContent sx={{ display: "flex" }}>
            <img
              alt="AI"
              width="30%"
              height="30%"
              src={`../../../assets/ddd.png`}
            />
            <Stack spacing={2}>
              <Typography variant="h4">Data-Driven Decision Making</Typography>
              <Typography variant="h6">
                Our advanced analytics tools can transform raw data into
                actionable insights. By analyzing historical trends, current
                performance, and future projections, we empower dairy businesses
                to make informed decisions that drive growth and profitability.
              </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: colors.blueAccent[600],
            width: "20%",
          }}
        >
          <CardContent sx={{ display: "flex" }}>
          <img
              alt="AI"
              width="25%"
              height="30%"
              src={`../../../assets/streamline-activities.png`}
            />
              <Stack spacing={2}>
              <Typography variant="h4">Streamlined Operations</Typography>
              <Typography variant="h6">
              Our efficient data management solutions
              can streamline your operations, reducing manual tasks and
              minimizing errors. By automating data entry, reporting, and
              analysis, we save you time and resources, allowing you to focus on
              core business activities.
            </Typography>
            </Stack>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: colors.greenAccent[500],
            width: "20%",
          }}
        >
          <CardContent sx={{ display: "flex" }}>
          <img
              alt="AI"
              width="30%"
              height="30%"
              src={`../../../assets/efficiency.png`}
            />
              <Stack spacing={2}>
              <Typography variant="h4">Enhanced Productivity</Typography>
              <Typography variant="h6">
              Our data-driven approach to
              herd health management can improve animal welfare and
              productivity. By tracking key metrics like milk production,
              reproductive cycles, and health records, we can identify potential
              issues early on and implement preventive measures.
            </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};

export default About;
