import { AppBar, Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./OAppBar.css";
import { tokens } from "../../theme";
import OButton from "../common/OButton";
import Item from "../common/Item";

const OAppBar = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const clickHandler = (page) => navigate(page);
  return (
    <AppBar>
      <Box className="AppBarContainer">
        {/* LOGO AND NAME */}
        <Link
          className="TopBarLeft"
          to="/"
          style={{
            textDecoration: "none",
          }}
        >
          <img
            alt="LOGO"
            width="60px"
            height="60px"
            src={`../../assets/M-Logo.png`}
          />

          <Typography
            variant="h3"
            sx={{
              color: colors.black[100],
              paddingLeft: "10px",
              fontWeight: 600,
              letterSpacing: '2px',

            }}
          >
            MILKO
          </Typography>
        </Link>
        {/* NAVBAR */}
        <Stack direction="row" spacing={2}>
          <Item content="About Us" />
          <Item content="Features" />
          <Item content="Support" />
          <Item content="Career" />
        </Stack>


          <OButton 
            variant='outlined'
            borderRadius="1.5rem"
            content="DEMO"
            height="3rem"
            width="6rem"
            color={colors.blueAccent[500]}
            onClick={clickHandler}
            // navigateTo="demo"
            btnContentColor= {colors.white[100]}
            contentVariant='h4'
            />

      </Box>
    </AppBar>
  );
};

export default OAppBar;
