import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link } from "react-router-dom";

const Item = ({ content }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ cursor:'pointer', opacity:1}}>
      <Typography
        component={Link}
        variant="h4"
        to="/profile"
        color={colors.black[100]}
        fontWeight='600'
        style={{ textDecoration: "none" }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default Item;