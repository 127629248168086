import React from "react";
import Hero from "../hero/Hero";
import About from "../about/About";
import Features from "../features/Features";
import Contact from "../contactus/Contact";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Features />
      <Contact />
    </div>
  );
};

export default Home;
