import React from 'react';
import { Box, Divider, List, ListItem, Paper, Stack, Typography } from '@mui/material';
import { tokens,useMode } from '../../theme';
import './Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Footer = () => {

  const [theme] = useMode();
  const colors = tokens(theme.palette.mode);

  return (
    <Paper
    className='footer-container'
    sx={{
      // backgroundColor: colors.blue[200]
    }}
    >
      {/* Top */}
      <Box
      className='footer-top'
      >
        <Box
        className='footer-about-us'
        >
          <List>
          <ListItem>
          <Typography 
            variant='h5'
            fontWeight={600}
            >
            ABOUT MILKO
            </Typography>
            </ListItem>
            <ListItem>Who We Are</ListItem>
            <ListItem>Work With Us</ListItem>
            <ListItem>Contact Us</ListItem>
          </List>
        </Box>

        <Box
        className='footer-learn-more'
        >
          <List>
          <ListItem>
            <Typography 
            variant='h5'
            fontWeight={600}
            >
            LEARN MORE
            </Typography>
            </ListItem>
            <ListItem>Privacy</ListItem>
            <ListItem>Security</ListItem>
            <ListItem>Terms</ListItem>
          </List>
        </Box>

        <Box
        className='footer-social-links'
        >
          <List>
          <ListItem>
          <Typography 
            variant='h5'
            fontWeight={600}
            >
            SOCIAL LINKS
            </Typography>
            </ListItem>
            <Stack direction='row'>
            <ListItem><FacebookIcon/></ListItem>
            <ListItem><InstagramIcon/></ListItem>
            <ListItem><XIcon/></ListItem>
            <ListItem><LinkedInIcon/></ListItem>
            </Stack>
            <Stack spacing={2} direction='column'
            sx={{
              marginTop:'15px',
              marginLeft:'15px'
            }}
            >
            <img width='100px' height='40px' alt='Apple Store' src='../../../assets/app-store.webp'/>
            <img width='100px' height='40px' alt='Apple Store' src='../../../assets/google-store.webp'/>
            </Stack>
          </List>
        </Box>

      </Box>
      <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      sx={{
        paddingBottom:10
      }}
      
      >
        <Typography
        variant='h5'
        color={colors.black[100]}
        >
          👋 JOIN ME IF YOU ARE A UI/UX DESIGNER OR FRONT DEVELOPER
        </Typography>
        <Typography color={colors.black[100]}>
          partner@milko.in
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: colors.black[100]}}/>

      {/* Bottom */}
      <Box
      className='footer-bottom'
      >
        <Typography
        variant='h6'
        color={colors.black[100]}
        > © 2024 Milko. All Rights Reserved. </Typography>
      </Box>
    </Paper>
  )
}

export default Footer;