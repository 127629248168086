import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import OAppBar from "./components/navbar/OAppBar";
import Footer from "./components/footer/Footer";
import Contact from "./components/contactus/Contact";
import { Route, Routes } from "react-router-dom";
import "./index.css";

import Home from "./components/home/Home";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <OAppBar />
          <main className="Main">
            <Routes>
            <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
